import React, { useEffect } from 'react';

import ImageBannerContainer from './Home/ImageBannerContainer';
import HomeInfoCardContainer from './Home/HomeInfoCardContainer';
import bannerImage from '../misc/images/janebanner.jpg';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  creditText: {
    fontSize: 13,
    fontFamily: "'Open Sans', sans-serif",
    color: "#404040",
  },
}));

function HomeView() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Home | Margaret Campbell";
  });
  
  return (
    <React.Fragment>
      <ImageBannerContainer image={bannerImage}/>
      <HomeInfoCardContainer/>
      <div className={classes.creditText}>
        Image by <a className={classes.creditText} href="https://unsplash.com/@janelastdcu" target="_blank" rel="noopener noreferrer">Jane Last</a>
      </div>
    </React.Fragment>
  )
}

export default HomeView;