import React, { useEffect } from 'react';

import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ContactUsPaper from '../common/ContactUsPaper';

import ricLogo from '../misc/images/ricedit.png';
import houseImage from '../misc/images/canstockphoto5729343.jpg';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: "48px",
  },
  pageTitle: {
    fontWeight: 600,
    marginBottom: "24px",
  },
  infoTextContainer: {
    textAlign: "left",

    fontSize: 17,
    fontFamily: "'Open Sans', sans-serif",
  },
  houseImageContainer: {
    width: "100%",
    maxWidth: 200,
    maxHeight: 200,
  },
  ricImageContainer: {
    width: "100%",
    maxWidth: 578,
    maxHeight: 93,

    marginTop: 24,
  },
}));

function ValuationView() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Valuation | Margaret Campbell";
  });

  return (
    <Container className={classes.rootContainer}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.pageTitle} color="textSecondary" variant="h3">
            VALUATION SERVICES
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography className={classes.infoTextContainer}>
            <p>Whether you are purchasing a property, re-mortgaging, need a valuation for legal reasons, all valuations reports include a professional opinion value of the property taking into account visual repairs that need to be carried out and calculate the re-instatement value for insurance purposes.</p>
            <p>If you are re-mortgaging or taking out a mortgage to buy a property, your mortgage provider normally obtains a valuation to assess the property’s suitability for mortgage purposes.</p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <img
            className={classes.houseImageContainer}
            src={houseImage}
            alt="Margaret Campbell Logo"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography className={classes.infoTextContainer}>
            <p>We carry out Residential Valuations in respect of new and second-hand properties on behalf of Clients, Financial institutions and Mortgage advisors. We are on the panel for most Lenders and provide a quick efficient service.</p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            className={classes.ricImageContainer}
            src={ricLogo}
            alt="Margaret Campbell Logo"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.infoTextContainer}>
            <p>We cover all areas in Dublin North, Dublin City, Dublin West, East Meath, South Louth and surrounding areas.</p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactUsPaper/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ValuationView;