import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom";

import CampbellAppBar from './views/CampbellAppBar';
import HomeView from './views/HomeView';
import ValuationView from './views/ValuationView';
import BERView from './views/BERView';

function App() {
  return (
    <Router>
      <div className="App">
        <CampbellAppBar/>
        <Switch>
          <Route path="/" component={HomeView} exact/>
          <Route path="/ber" component={BERView}/>
          <Route path="/valuation" component={ValuationView}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
