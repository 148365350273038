import React from 'react';

import { Card, CardMedia, CardActions, CardContent, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    width: 280,
    height: 320,

    paddingTop: 48,
  },
  media: {
    width: 108,
    height: 108,
  },
  learnButton: {
    fontSize: 16,
    fontWeight: 600,

    backgroundColor: "#FFFFFF",
    borderColor: "#6680CB",
    color: "#6680CB",
  },
}));

function InfoCards(props) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardMedia
          className={classes.media}
          image={props.image}
          title="Contemplative Reptile"
        />
      <CardContent>
        <Typography variant="h3">
          {props.title}
        </Typography>
      </CardContent>
      <CardActions>
      <Button variant="outlined" size="large" className={classes.learnButton}  href={props.url}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  )
}

export default InfoCards;