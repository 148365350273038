import React from 'react';

import { Grid, Container, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import homeSvg from '../../misc/images/home.svg';
import sunSvg from '../../misc/images/sun.svg';

import ContactUsPaper from '../../common/ContactUsPaper';
import InfoCards from '../../common/InfoCards';

const useStyles = makeStyles((theme) => ({
  infoCardTitle: {
    marginTop: "40px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  gridItem: {
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  aboutPaper: {
    marginTop: "40px",
    marginBottom: "20px",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 17,
  },
  contactButton: {
    marginTop: "40px",
    marginBottom: "20px",

    fontFamily: "'Open Sans', sans-serif",
    fontSize: "2rem",
    fontWeight: 600,
  },
  blackLink: {
    color: "#000000"
  },
}));

const HomeInfoCardContainer = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography className={classes.infoCardTitle} color="textSecondary" variant="h3">
          OUR SERVICES
      </Typography>
      <Grid container justify={"center"}>
        <Grid item className={classes.gridItem}>
          <InfoCards image={homeSvg} title={"Property Valuations"} url={"/valuation"}/>
        </Grid>
        <Grid item className={classes.gridItem}>
          <InfoCards image={sunSvg} title={"BER Assessment"} url={"/ber"}/>
        </Grid>
      </Grid>
      <Typography className={classes.infoCardTitle} color="textSecondary" variant="h3">
          ABOUT US
      </Typography>
      <Paper variant="outlined" className={classes.aboutPaper}>
        <p>Margaret Campbell Property Consultants Ltd is based in North County Dublin.</p>
        <p>We offer the following services:</p>
        <p><b>- Property Valuations -</b></p>
        <p><b>- Building Energy Rating Assessments -</b></p>
        <p>The company was set up in 2005 by Margaret Campbell who has extensive experience (over 25 years) in Property Valuations, Auctioneering and Banking.</p>
        <p>A Registered Valuer, qualified Member of the Society of Chartered Surveyors and Royal Institute of Chartered Surveyors we have the expertise and experience to provide a valuation report that adheres to the Red Book professional standards in a timely manner.</p>
        <p>We are fully qualified and registered Domestic BER Assessor with Sustainable Energy Authority of Ireland (SEAI).</p>
        <p>We hold full Professional Indemnity Insurance Cover.</p>
      </Paper>
      <ContactUsPaper/>
    </Container>
  )
}

export default HomeInfoCardContainer;