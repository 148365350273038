import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: "100%",
  },
}));

const ImageBannerContainer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      <img className={classes.image} src={props.image} alt="Home Banner"/>
    </div>
  )
}

export default ImageBannerContainer;