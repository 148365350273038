import React from 'react';

import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NavDrawer from './NavDrawer';
import logo from '../misc/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    color: "#444444",
    boxShadow: "none",
    position: "sticky",
  },
  imageContainer: {
    flex: 1,
    maxHeight: 62
  },
  buttonContainer: {
    flex: 1,
  },
  buttonStyle: {
    fontWeight: 600,
  },
  toolBarStyle: {
    paddingLeft: 4,
  },
}));

function CampbellAppBar() {
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolBarStyle}>
        <div className={classes.imageContainer}>
          <a href="/">
            <img
              className={classes.imageContainer}
              src={logo}
              alt="Margaret Campbell Logo"
            />
          </a>
        </div>
        <div className={classes.buttonContainer}>
          {/* <Button outlined color="inherit" className={classes.buttonStyle}>
            HOME
          </Button>
          <Button outlined color="inherit" className={classes.buttonStyle}>
            VALUATION
          </Button>
          <Button outlined color="inherit" className={classes.buttonStyle}>
            BER
          </Button> */}
          <NavDrawer/>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default CampbellAppBar;