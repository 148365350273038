import React from 'react';

import {Paper, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  infoCardTitle: {
    marginTop: "40px",
    marginBottom: "20px",
    fontWeight: 600,
  },
  aboutPaper: {
    marginTop: "40px",
    marginBottom: "20px",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 17,
  },
  blackLink: {
    color: "#000000"
  },
}));

const ContactUsPaper = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.infoCardTitle} color="textSecondary" variant="h3">
          CONTACT US
      </Typography>
      <Paper variant="outlined" className={classes.aboutPaper}>
        <p>
          <strong>Want to get in touch?</strong> Email us today at&nbsp;
          <strong><a className={classes.blackLink} href="mailto:margmcampbell@gmail.com" target="_blank" rel="noopener noreferrer">margmcampbell@gmail.com</a>&nbsp;</strong>
          or call at <strong><a className={classes.blackLink} href="tel:0872834996" target="_blank" rel="noopener noreferrer">0872834996</a>&nbsp;</strong>
        </p>
      </Paper>
    </>
  )
}

export default ContactUsPaper;