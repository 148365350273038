import React, { useEffect } from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ContactUsPaper from '../common/ContactUsPaper';

import greenImage from '../misc/images/greenhousesun.jpg';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: "48px",
  },
  pageTitle: {
    fontWeight: 600,
    marginBottom: "24px",
  },
  houseImageContainer: {
    width: "100%",
    maxWidth: 200,
    maxHeight: 200,
  },
  subTitle: {
    paddingTop: "24px",

    fontWeight: 600,
    textAlign: "left",
  },
  infoTextContainer: {
    textAlign: "left",

    fontSize: 17,
    fontFamily: "'Open Sans', sans-serif",
  },
  subInfoTextContainer: {
    textAlign: "left",

    fontSize: 17,
    fontFamily: "'Open Sans', sans-serif",
  },
}));

function ValuationView() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "BER | Margaret Campbell";
  });

  return (
    <Container className={classes.rootContainer}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.pageTitle} color="textSecondary" variant="h3">
            BER SERVICES
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography className={classes.infoTextContainer}>
            <p>We carry out Residential BER Assessments for Vendors, Landlords and for Homeowners. We have been trained under the National Framework of Qualifications, passed the SEAI BER exams and a Registered Assessor with SEAI.</p>
            <p><b>WE OFFER COMPREHENSIVE BER SERVICE AT COMPETITIVE PRICES WITHIN A QUICK TIME FRAME.</b></p>
          </Typography>
          <Typography className={classes.subTitle} color="textSecondary" variant="h4">
            What is BER?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <img
            className={classes.houseImageContainer}
            src={greenImage}
            alt="Margaret Campbell Logo"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.subInfoTextContainer}>
            <p>A Building Energy Rating (BER) Certificate is an indication of the energy performance of a home. A BER Certificate is accompanied by an Advisory Report which identifies how you might improve the energy performance of your home.</p>
            <p><strong>A BER is compulsory for Properties offered for Sale or Rent.</strong> A BER is also required before a new home is occupied for the first time.</p>
            <p>There are exemptions for certain categories of dwellings, for example protected structures.</p>
            <p><strong>Advertisements must include BER details when a home is offered for Sale or Rent.</strong></p>
            <p>If you are buying or renting a house or apartment, you are entitled to a BER, so ask the Vendor, Landlord or Agent for it.</p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactUsPaper/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ValuationView;