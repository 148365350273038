import React from 'react';

import { Button, Drawer, List, ListItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';

const navDrawerLocation = "top";

const useStyles = makeStyles({
  fullList: {
    width: 'auto',

    fontWeight: 600,
  },
  listItem: {
    justifyContent: "center",
    color: "#002b99",
  },
  menuIconStyle: {
    color: "#002b99",
  }
});

function NavDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    navDrawerLocation: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [navDrawerLocation]: open });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const list = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["home", "valuation", "ber"].map((text, index) => (
          <ListItemLink 
            href={
              index === 0 ? "./" : "./" + text
            }
            key={text}
            className={classes.listItem}
            >
              {text.toUpperCase()}
          </ListItemLink >
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon className={classes.menuIconStyle}/>
      </Button>
      <Drawer
        anchor={navDrawerLocation}
        open={state[navDrawerLocation]}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default NavDrawer;